<template>
    <b-modal :id="modalId" modal-class="modal-w-xxxl" title="Activity" hide-footer>
        <template #modal-header="{ close }">
            <div class="form-add activity-notes-section">
                <!--<h2 class="modal-title">Activity</h2>-->
                <button @click="close()" type="button" aria-label="Close" class="close"></button>

                <!--<p>Select an interaction to log:</p>-->

                <div class="activity-notes-section__wrapper">
                    <div class="activity-notes-section__inner">
                        <p>Log an interaction:</p>
    
                        <div class="activity-notes-list p-0 mb-3">
                            <a @click="modalInteractionType = 'meeting'" class="activity-notes-list__meeting"
                               :class="{active: modalInteractionType === 'meeting'}">Meeting</a>
                            <a @click="modalInteractionType = 'email'" class="activity-notes-list__email"
                               :class="{active: modalInteractionType === 'email'}">Email</a>
                            <a @click="modalInteractionType = 'phone_call'" class="activity-notes-list__call"
                               :class="{active: modalInteractionType === 'phone_call'}">Call</a>
                            <a @click="modalInteractionType = 'demo'" class="activity-notes-list__demo"
                               :class="{active: modalInteractionType === 'demo'}">Demo</a>
                        </div>

                        <p>More actions:</p>

                        <div class="activity-notes-list p-0">
                            <a @click="modalInteractionType = 'note'" class="activity-notes-list__note"
                               :class="{active: modalInteractionType === 'note'}">Note</a>
                            <a @click="modalInteractionType = 'news'" class="activity-notes-list__news"
                               :class="{active: modalInteractionType === 'news'}">News</a>
                        </div>
                    </div>
                    <div class="activity-notes-section__form">
                        <add-note
                            v-if="modalInteractionType == 'note'"
                            companyType="startup"
                            :companyId="companyId"
                            :usersMentionList="usersMentionList"
                            @saved="activityChanged"
                        >
                        </add-note>

                        <add-interaction
                            v-if="['phone_call','email','meeting','demo'].includes(modalInteractionType)"
                            :type="modalInteractionType"
                            companyType="startup"
                            :companyId="companyId"
                            :usersMentionList="usersMentionList"
                            @saved="activityChanged"
                        >
                        </add-interaction>

                        <AddArticle
                            v-if="modalInteractionType == 'news'"
                            :startup-id="companyId"
                            @saved="activityChanged"
                        >
                        </AddArticle>
                    </div>
                </div>
            </div>
        </template>

        <div class="accordion accordion--secondary accordion--tabs">
            <div class="accordion__header-wrapper">
                <b-dd ref="activityFilter" id="dropdown-activity-filter" class="dropdown-activity-filter" :text="filtersLabel">
                    <b-dd-text tag="div" text-class="input-checkbox">
                        <input id="input-checkbox__all" type="checkbox" v-model="selectAllFilters">
                        <label for="input-checkbox__all">Select All</label>
                    </b-dd-text>
                    <b-dd-divider></b-dd-divider>
                    <b-dd-text v-for="filter in filters" :key="filter.value" tag="div" text-class="input-checkbox">
                        <input :id="'input-checkbox__' + filter.value" type="checkbox" :value="filter.value"
                               v-model="selectedTypes">
                        <label :for="'input-checkbox__' + filter.value">{{filter.name}}</label>
                    </b-dd-text>
                    <li class="dropdown-activity-filter__item">
                        <b-button class="button button--full mb-3" variant="primary" @click="hideActivityFilter(); getActivities()">Apply</b-button>
                        <b-button v-if="$store.state.user.is_admin" class="button button--full button--bordered" @click="saveFilters()">Save As Company Default</b-button>
                    </li>
                </b-dd>

                <div v-if="showOrderBy" class="tasks__select"
                     v-click-outside="function() {openActivityOrderSelect = false}">
                    <div @click="openActivityOrderSelect = true" class="tasks__select__chosen">
                        <span :title="activityOrderTypes[activityOrder]">{{activityOrderTypes[activityOrder]}}</span>
                    </div>
                    <ul v-show="openActivityOrderSelect" class="multiselect__content multiselect__content--date">
                        <li class="multiselect__element" v-for="(name, value) in activityOrderTypes" :key="value"
                            @click="activityOrder = value; openActivityOrderSelect = false; getActivities()">{{name}}
                        </li>
                    </ul>
                </div>
            </div>

            <activity-list
                :activities="activities"
                :companyId="companyId"
                :usersMentionList="usersMentionList"
                :activityLoading="activityLoading"
                :hasActivity="hasActivity"
                @activityChanged="activityChanged"
            ></activity-list>
        </div>
    </b-modal>
</template>

<script>
    import { mapMutations } from "vuex"
    import startups from "@/services/startups.service"
    import user from "@/services/user.service"
    import Vue from "vue";

    export default {
        name: 'ActivityModal',
        components: {
            AddNote: () => import("@/components/notes/EditNoteInline"),
            AddInteraction: () => import("@/components/interactions/EditInteractionInline"),
            AddArticle: () => import("@/components/articles/EditArticleInline"),
            ActivityList: () => import("@/components/startups/ActivityList"),
        },
        props: {
            companyId: {type: Number, required: true},
            interactionType: {type: String, required: true},
            usersMentionList: {type: Array, required: true},
        },
        data() {
            return {
                modalId: "activity-modal",

                modalInteractionType: this.interactionType,

                activities: [],
                hasActivity: null,
                activityLoading: false,

                filters: [
                    {value: 'news', name: 'News'},
                    {value: 'cb_changes', name: 'SwitchPitch Updates'},
                    {value: 'notes', name: 'Notes'},
                    {value: 'interactions', name: 'Interactions'},
                    {value: 'users_activity', name: 'Users Activity'},
                ],
                selectedTypes: [],
                selectAllFilters: false,

                activityOrder: 'created_at',
                openActivityOrderSelect: false,
                activityOrderTypes: {
                    'created_at': 'Date added',
                    'interaction_date': 'Interaction date',
                },
            }
        },
        created() {
            this.$store.watch(state => {
                if (state.user.settings.activity_filters) {
                    state.user.settings.activity_filters.length ? this.selectedTypes = [...state.user.settings.activity_filters] : this.selectAllFilters = true
                }
            })
        },
        watch: {
            interactionType() {
                this.modalInteractionType = this.interactionType
            },
            selectedTypes() {
                // this.selectAllFilters = this.selectedTypes.length === this.filters.length
            },
            selectAllFilters() {
                this.selectedTypes = this.selectAllFilters ? this.filters.map(filter => filter.value) : []
            },
        },
        computed: {
            filtersLabel() {
                return this.selectedTypes.length === this.filters.length ? "All Activity" : "Filtered Activity (" + this.selectedTypes.length + ")"
            },
            showOrderBy() {
                return this.selectedTypes.includes('interactions')
            }
        },
        methods: {
            ...mapMutations(['setProfileActivityFilters', 'setNeedUpdateLatestActivity']),

            open() {
                this.activities = []
                this.getActivities()
                this.$bvModal.show(this.modalId)
            },

            close() {
                this.$bvModal.hide(this.modalId)
            },

            getActivities() {
                this.activityLoading = true

                startups.getActivities(this.companyId, {
                    sort: this.activityOrder,
                    filter: {types: this.selectedTypes},
                }).then(response => {
                    this.activityLoading = false
                    this.activities = response.data

                    this.hasActivity = !!this.activities.length
                })
            },

            activityChanged() {
                this.getActivities()
                this.$emit('activityChanged')
            },

            hideActivityFilter() {
                this.$refs.activityFilter.hide()
            },

            saveFilters() {
                user.saveProfileActivityFilter(this.selectedTypes)
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: "Filters saved as company default"
                })

                this.setNeedUpdateLatestActivity(true)
                this.setProfileActivityFilters(this.selectedTypes)
                this.getActivities()
            },
        }
    }
</script>

<style scoped>

</style>
